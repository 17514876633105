import React, { useState } from 'react';
import '../index.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import homeCard from '../images/3d-card.png';
import bloodIcon from '../images/blood-icon.svg';
import Modal from 'react-bootstrap/Modal';
import logo from '../images/logo.svg';
import logo2 from '../images/logo-2.svg';
import axios from "axios";
import arrowLeft from '../images/arrow-left.png';
import arrowRight from '../images/arrow-right.png';
import $ from "jquery";
import ReactGA from "react-ga";

class ViewCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            encodedCardID: this.props.id,
            cardName: "",
            cardUtente: "",
            cardDate: "",
            cardDoctor: "",
            cardHospital: "",
            cardReason: "",
            cardSolution: "",
            cardBloodDate: "",
            cardPin: "",
            pin: "",
            pinModal: false,
            pinError: 0,
            zoom: 1,
        }
        this.validatePin = this.validatePin.bind(this);
    }

    componentDidMount() {
        this.handleResize(); // Chama na primeira renderização
        window.addEventListener("resize", this.handleResize);
        ReactGA.pageview(window.location.pathname);
        // START: GET HOME PAGE TEXT
        axios.get(global.config.apiUrl + "publicCard/" + this.state.encodedCardID)
            .then(res => {
                this.setState({ cardName: res.data.NAME });
                this.setState({ cardUtente: res.data.UTENTE });
                this.setState({ cardDate: res.data.ISSUANCE_DATE });
                this.setState({ cardDoctor: res.data.DOCTOR });
                this.setState({ cardHospital: res.data.HOSPITAL });
                this.setState({ cardReason: res.data.BLOOD_REASON });
                this.setState({ cardSolution: res.data.BLOOD_SOLUTION });
                this.setState({ cardBloodDate: res.data.BLOOD_DATE });
                this.setState({ cardPin: res.data.PIN });
                if (sessionStorage.getItem('cardPin') != null && sessionStorage.getItem('cardPin') != '') {
                    this.validatePin(false, res.data.PIN, sessionStorage.getItem('cardPin'));
                }
                else {
                    this.validatePin(false);
                }
                console.log('BLOOD_REASON', res.data.BLOOD_REASON);
            })
        // END: GET HOME PAGE TEXT
    }
    componentDidUpdate() {
        // START: GET HOME PAGE TEXT
        const userData = { page_id: this.props.id, lang: global.config.lang }
        axios.post(global.config.apiUrl + "getPage", userData)
            .then(res => {
                this.setState({ pageTitle: res.data.NAME });
                this.setState({ pageText: res.data.DESCRIPTION });
                this.setState({ pageTitleSEO: res.data.TITLE_SEO });
                this.setState({ pageDescSEO: res.data.DESCRIPTION_SEO });
            })
        // END: GET HOME PAGE TEXT
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        const screenWidth = window.outerWidth;
        console.log('screenWidth', screenWidth);
        if (screenWidth <= 768) {
            const newZoom = (screenWidth - 30) / 535; // Ajusta o zoom com base na tela, subtraindo as margens
            this.setState({ zoom: newZoom });
            console.log('zoom', newZoom);
        } else {
            this.setState({ zoom: 1 }); // Zoom padrão no desktop
        }
    };

    rotateLeft() {
        if ($('.flip-card-inner').css('transform') == 'none') $('.flip-card-inner').css('transform', 'rotateY(-180deg)');
        else $('.flip-card-inner').css('transform', '');
    }
    rotateRight() {
        if ($('.flip-card-inner').css('transform') == 'none') $('.flip-card-inner').css('transform', 'rotateY(180deg)');
        else $('.flip-card-inner').css('transform', '');
    }
    validatePin(btnPress, pinArg, cardPinArg) {
        var pin = '';
        if (pinArg == null) pin = this.state.pin;
        else pin = pinArg;
        var cardPin = '';
        if (cardPinArg == null) cardPin = this.state.cardPin;
        else cardPin = cardPinArg;
        if (cardPin == pin && pin != '') {
            /*
            this.setState({ pinModal: false });
            this.setState({ pinError: 0 });
            */
            sessionStorage.setItem('cardPin', cardPin);
        }
        else {
            window.location.href = '/cartao-irradiacao';
            /*
            this.setState({ pinModal: true });
            if(btnPress) this.setState({ pinError: 1 });
            */
        }
    }
    render() {
        const { zoom } = this.state;

        return (
            <div className="home-container">
                <Modal show={this.state.pinModal} backdrop='static' id="pin-modal" centered keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Validar PIN</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card-user-number validate-pin-container">
                            <label>PIN</label>
                            <input
                                type="text"
                                placeholder="----"
                                value={this.state.pin}  // Controla o valor do input pelo state
                                maxLength="4"  // Limita o número de caracteres
                                onChange={e => {
                                    const value = e.target.value.replace(/\D/g, '');  // Permite apenas números
                                    if (value.length <= 4) {  // Garante que não ultrapasse 4 dígitos
                                        this.setState({ pin: value });
                                    }
                                }}
                            />
                        </div>
                        {this.state.pinError == 1 &&
                            <p className="error-message error-message-pin">PIN Incorreto</p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.validatePin(true)}>
                            Validar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div id="home-intro" className="home-intro">
                    <Container>
                        <Row>
                            <Col md={3}>
                                <h3>Cartão Individual de Irradiação</h3>
                                <div className="spacer20"></div>
                                {/*
                    <p>Reveja o cartão, para se certificar que os dados estão todos correcto. Se precisar de editar as informações do cartão criado, clique em “Editar cartão” por baixo do cartão do lado direito, para editar as informadores pretendidas.</p>
                    */}
                            </Col>
                            <Col md={9}>
                                <div style={{ width: 535 }} className="card-right-container">
                                    <div className="flip-card" style={{ zoom }}>
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front">
                                                <div id="created-card">
                                                    <div className="card-top">
                                                        <img src={logo} className="card-logo" alt="RayBlood" />
                                                        <p>Risco de desenvolver</p>
                                                        <h1>Doença do Enxerto Contra o Hospedeiro Associado à Transfusão</h1>
                                                    </div>
                                                    <div className="card-bottom">
                                                        <p>Se necessitar de uma transfusão de componentes sanguíneos (eritrócitos, plaquetas e granulócitos) eles <b>devem ser IRRADIADOS. Por favor informe o Serviço de Medicina Transfusional.</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flip-card-back">
                                                <div id="created-card-back">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="card-info-container" style={{ paddingTop: 0 }}>
                                                                <label>Nome:</label>
                                                                <span>{this.state.cardName}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="card-info-container">
                                                                <label>Nº Utente:</label>
                                                                <span>{this.state.cardUtente}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="card-info-container">
                                                                <label>Data emissão:</label>
                                                                <span>{this.state.cardDate}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="card-info-container">
                                                                <label>Médico:</label>
                                                                <span>{this.state.cardDoctor}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="card-info-container">
                                                                <label>Hospital:</label>
                                                                <span>{this.state.cardHospital}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="card-info-container-reason">
                                                                <label>Motivo sangue irradiado:</label>
                                                                <span style={this.state.cardReason.length > 140 ? { fontSize: 13 } : {}}>{this.state.cardReason}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12" style={this.state.cardReason == 'Outra' ? { visibility: 'hidden', maxHeight: 40 } : {}}>
                                                            <label className="label-red" style={{ marginTop: 5 }}>{this.state.cardSolution}</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="card-info-container">
                                                                <label className="label-red">Até:</label>
                                                                <span>{this.state.cardBloodDate == "30/11/-0001" ? '--/--/----' : this.state.cardBloodDate}</span>
                                                            </div>
                                                            <label className="label-gray">Mais informações em <a href="#">www.rayblood.pt</a></label>
                                                        </div>
                                                        <div className="col-6 card-info-logo">
                                                            <img src={logo2} className="footer-logo" alt="logo" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, position: 'relative' }}>
                                        <a href="javascript:;" onClick={this.rotateLeft} className="card-arrow-left"><img src={arrowLeft} /></a>
                                        <a href="javascript:;" onClick={this.rotateRight} className="card-arrow-right"><img src={arrowRight} /></a>
                                        <div style={{ width: 150, marginLeft: 40 }}><span style={{ color: '#939090', fontWeight: 100 }}>Clique nas setas para ver ambos os lados</span></div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 40 }}>
                                        <a href="#" className="btn-primary">Imprimir cartão</a>
                                        <a href={"https://admin.rayblood.pt/admin/createPDF/" + this.state.encodedCardID} className="btn-secondary">Descarregar cartão em PDF</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default ViewCard;
