import React from 'react';
import '../index.css';
import '../config.js';
import Container from 'react-bootstrap/Container';
import axios from "axios";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";


class Page extends React.Component {
  constructor(props){
    super(props);
		this.state = {
			pageTitle: '',
			pageText: '',
      pageTitleSEO: '',
      pageDescSEO: '',
		}
	}
  componentDidMount(){
    // START: GET HOME PAGE TEXT
    ReactGA.pageview(window.location.pathname);
		const userData = {page_id: this.props.id, lang: global.config.lang}
    axios.post(global.config.apiUrl+"getPage", userData)
    .then(res => {
      this.setState({ pageTitle: res.data.NAME });
      this.setState({ pageText: res.data.DESCRIPTION });
      this.setState({ pageTitleSEO: res.data.TITLE_SEO });
      this.setState({ pageDescSEO: res.data.DESCRIPTION_SEO });
		})
		// END: GET HOME PAGE TEXT
  }
  componentDidUpdate(){
    // START: GET HOME PAGE TEXT
		const userData = {page_id: this.props.id, lang: global.config.lang}
    axios.post(global.config.apiUrl+"getPage", userData)
    .then(res => {
      this.setState({ pageTitle: res.data.NAME });
      this.setState({ pageText: res.data.DESCRIPTION });
      this.setState({ pageTitleSEO: res.data.TITLE_SEO });
      this.setState({ pageDescSEO: res.data.DESCRIPTION_SEO });
		})
		// END: GET HOME PAGE TEXT
  }
  render(){
    return (
      <div className="page-container">
          <Helmet>
            <title>{this.state.pageTitleSEO}</title>
            <meta name="description" content={this.state.pageDescSEO}/>
          </Helmet>
          <Container>
            <h1 className="page-title">{this.state.pageTitle}</h1>
            <div className="page-desc" dangerouslySetInnerHTML={{__html: this.state.pageText}}></div>
          </Container>
      </div>
    );
  }
}

export default Page;
