import React, { useState } from 'react';
import '../index.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextInput from '../components/TextInput';
import axios from "axios";
import $ from "jquery";
import ReactGA from "react-ga";

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			nameValue: '',
			emailValue: '',
      messageValue: '',
      nameError: 0,
      emailError: 0,
      messageError: 0,
      textareaCount: 0,
      formSuccess: false,
		}
  }

  componentDidMount(){
    ReactGA.pageview(window.location.pathname);
  }

  handleNameChange = (e) => {
    this.setState({ nameValue: e.target.value });
  }
  handleEmailChange = (e) => {
    this.setState({ emailValue: e.target.value });
  }
  handleMessageChange = (e) => {
    this.setState({ messageValue: e.target.value });
  }

  textAreaAdjust = (e) => {
    if($('#messageTextarea').prop("scrollHeight") > 65){
      $('#messageTextarea').css('height','1px');
      $('#messageTextarea').css('height',($('#messageTextarea').prop("scrollHeight"))+"px");
    }
    $('.textarea-char-count').text(($('#messageTextarea').text().length+1)+'/1000');
  }
  
  sendQuestion = () => {
    if(this.state.nameValue != '' && this.state.emailValue != '' && this.state.messageValue != ''){
      this.setState({ nameError: 0 });
      this.setState({ emailError: 0 });
      this.setState({ messageError: 0 });
      const messageData = {name: this.state.nameValue, email: this.state.emailValue, message: this.state.messageValue}
      axios.post(global.config.apiUrl+"insertMessage", messageData)
      .then(res => {
        this.setState({ formSuccess: true });
      })
    }
    else{
      this.setState({ nameError: 0 });
      this.setState({ emailError: 0 });
      this.setState({ messageError: 0 });
      if(this.state.nameValue == ''){
        this.setState({ nameError: 1 });
      }
      if(this.state.emailValue == ''){
        this.setState({ emailError: 1 });
      }
      if(this.state.messageValue == ''){
        this.setState({ messageError: 1 });
      }
    }
  }

  sendOtherQuestion = () => {
    window.location.reload();
  }

  render() {
    return (
      <div className="home-container">
        <div className="home-banner">
          <Container>
            <Row>
              <Col md={6} className={this.state.formSuccess ? 'hide-form' : null}>
                <p className='question-text'>
                Entre em contacto com um dos colaboradores RayBlood, caso tenha alguma dúvida ou questão relativa à Doença do Enxerto Contra o Hospedeiro Associada à Transfusão. O colaborador responderá com a maior brevidade possível. Obrigado.
                </p>
              </Col>
              <Col md={6} className={this.state.formSuccess ? 'hide-form' : null}>
                <div className="question-form-container">
                  <form id="question-form">
                    <div className="input-container">
                      <input type="text" value={this.state.nameValue} name="name" onChange={this.handleNameChange} />
                      <label className={this.state.nameValue!='' && 'filled'} htmlFor="name">
                        Nome
                      </label>
                      {this.state.nameError == 1 &&
                        <p className="error-message">*Campo Obrigatório</p>
                      }
                    </div>
                    <div className="input-container">
                      <input type="text" value={this.state.emailValue} name="email" onChange={this.handleEmailChange} />
                      <label className={this.state.emailValue!='' && 'filled'} htmlFor="email">
                        E-mail
                      </label>
                      {this.state.emailError == 1 &&
                        <p className="error-message">*Campo Obrigatório</p>
                      }
                    </div>
                    <div className="input-container">
                      <textarea onKeyUp={this.textAreaAdjust()} id="messageTextarea" name="message" maxLength="1000" style={{overflow:'hidden'}} value={this.state.messageValue} onChange={this.handleMessageChange}></textarea>
                      <label className={this.state.messageValue!='' && 'filled'} htmlFor="message">
                        Mensagem
                      </label>
                      <p className="textarea-char-count">0/1000</p>
                      {this.state.messageError == 1 &&
                        <p className="error-message">*Campo Obrigatório</p>
                      }
                    </div>
                    <button type="button" onClick={this.sendQuestion} className="btn-secondary">Enviar Mensagem</button>
                  </form>
                </div>
              </Col>
              <Col md={12} className={this.state.formSuccess ? 'form-success' : 'hide-form'}>
                <div>
                  <p className='success-text'>
                    Questão enviada com sucesso.
                  </p>
                  <button onClick={this.sendOtherQuestion} className="btn-secondary">
                    Colocar outra questão
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Question;
