import React, { useEffect } from 'react';

const CookieConsentScript = () => {
  useEffect(() => {
    // Adiciona o script principal do Cookie Script
    const script = document.createElement('script');
    script.src = "//cdn.cookie-script.com/s/cea5a2c3e52358d29c6d5c6524f05d72.js";
    script.type = "text/javascript";
    script.charset = "UTF-8";
    document.head.appendChild(script);

    const consentScript = document.createElement('script');
    consentScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('consent', 'default', {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied'
      });
    `;
    consentScript.type = "text/javascript";
    document.head.appendChild(consentScript);

    // Função para ativar o consentimento ao clicar em "Aceitar Todos"
    const handleAcceptClick = () => {
      console.log('Cookies aceitos, Google Analytics habilitado');
      window.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
      });
    };

    // Observa mudanças no DOM para encontrar o botão dinamicamente
    const observer = new MutationObserver(() => {
      const acceptButton = document.getElementById('cookiescript_accept');
      if (acceptButton) {
        acceptButton.addEventListener('click', handleAcceptClick);
        observer.disconnect(); // Para de observar após encontrar o botão
      }
    });

    // Inicia a observação do body para mudanças no DOM
    observer.observe(document.body, { childList: true, subtree: true });

    // Limpeza ao desmontar o componente
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(consentScript);
      observer.disconnect(); // Certifica-se de parar o observador
    };
  }, []);

  return null;
};

export default CookieConsentScript;
