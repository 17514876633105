import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from './template/Header';
import Footer from './template/Footer';
import Home from './screens/Home';
import Card from './screens/Card';
import Page from './screens/Page';
import ViewCard from './screens/ViewCard';
import Question from './screens/Question';
import Login from './screens/Login';
import Register from './screens/Register';
import WebApp from './screens/WebApp';
import './Bootstrap/bootstrap.min.css';
import './index.css';
import usePageTracking from './components/usePageTracking.js';
import ReactGA from "react-ga";
import axios from "axios";
import {Helmet} from "react-helmet";
import CookieConsentScript from './components/CookieConsentScript'; 
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link,
  useParams
  } from "react-router-dom";

  
const HomeFunc = props => {
  let { id } = useParams();
  //usePageTracking();

  return <Home id={id} {...props} />
}

const CardFunc = props => {
  let { id } = useParams(); 
  
  //usePageTracking();

  return <ViewCard id={id} {...props} />
}

if(window.location.pathname.split('.')[1] != null){
  global.config.urlLang = window.location.pathname.split('.')[1];
  if(window.location.pathname.split('.')[1] == 'com') global.config.lang = 'EN';
}
ReactGA.initialize("G-577YNVPNQC");
const configData = {lang: global.config.lang}
axios.post(global.config.apiUrl+"getConfig", configData)
.then(res1 => {
  const pageData = {lang: global.config.lang}
  axios.post(global.config.apiUrl+"getAllPages", pageData)
  .then(res => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <Router>
          <Helmet>
            <title>{res1.data.TITLE_SEO}</title>
            <meta name="description" content={res1.data.DESCRIPTION_SEO}/>
            <meta property="og:image" content={global.config.uploadsPath+res1.data.IMAGE_CARD}/>
            <meta property="og:image:type" content="image/png"/>
            <meta property="og:image:width" content="1024"/>
            <meta property="og:image:height" content="1024"/>
            <meta property="og:type" content="website" />
            <meta property="og:url" content={window.location.href}/>
            <meta property="og:title" content={res1.data.TITLE_SEO} />
            <meta property="og:description" content={res1.data.DESCRIPTION_SEO} />
          </Helmet>
          <CookieConsentScript />
          <Routes>
            <Route exact path="/" element={
              <div>
                <Header />
                <Home />
                <Footer />
              </div>
            } />
            <Route exact path="/rayblood/:id" element={
              <div>
                <Header />
                <HomeFunc />
                <Footer />
              </div>
            } />
            {res.data.map(page => (  
              <Route key={page.ID} exact path={"/"+page.URL} element={
                <div>
                  <Header />
                  <Page id={page.ID} />
                  <Footer />
                </div>
              } />
            ))}
            <Route exact path="/cartao-irradiacao" element={
              <div>
                <Header />
                <Card />
                <Footer />
              </div>
            } />
            <Route exact path="/viewcard" element={
              <div>
                <Header />
                <ViewCard />
                <Footer />
              </div>
            } />
            <Route exact path="/question" element={
              <div>
                <Header />
                <Question />
                <Footer />
              </div>
            } />
            <Route exact path="/web-app" element={
              <div>
                <Header />
                <WebApp />
                <Footer />
              </div>
            } />
            <Route exact path="/login" element={
              <div>
                <Header />
                <Login />
                <Footer />
              </div>
            } />
            <Route exact path="/registo-profissionais" element={
              <div>
                <Header />
                <Register />
                <Footer />
              </div>
            } />
            <Route exact path="/card/:id" element={
              <div>
                <Header />
                <CardFunc />
                <Footer />
              </div>
            } />
          </Routes>
        </Router>
    );
  })
})



