import React, {useState} from 'react';
import '../index.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import bloodIcon from '../images/blood-icon.svg';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import logo from '../images/logo.svg';
import logoFooter from '../images/logo-footer.svg';
import arrowLeft from '../images/arrow-left.png';
import arrowRight from '../images/arrow-right.png';
import DOMPurify from 'dompurify';
import $ from "jquery";
import ReactGA from "react-ga";
import {
	Link
  } from "react-router-dom";

const utilizeFocus2 = () => {
  const ref = React.createRef()
  const setFocus = () => {ref.current &&  ref.current.focus()}

  return {setFocus, ref} 
}
const utilizeFocus3 = () => {
  const ref = React.createRef()
  const setFocus = () => {ref.current &&  ref.current.focus()}

  return {setFocus, ref} 
}
const utilizeFocus4 = () => {
  const ref = React.createRef()
  const setFocus = () => {ref.current &&  ref.current.focus()}

  return {setFocus, ref} 
}



class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeTitle: '',
      homeText: '',
      homeImage1: '',
      homeImage2: '',
      homeImage3: '',
      utente: '',
			pin1: '',
			pin2: '',
      pin3: '',
      pin4: '',
      cardError: 0,
		}
    this.inputFocus2 = utilizeFocus2();
    this.inputFocus3 = utilizeFocus3();
    this.inputFocus4 = utilizeFocus4();
  }
  updateUtente(evt) {
    this.setState({ utente: evt.target.value });
  }
  updatePin1(evt) {
    if(evt.target.value.length == 1 || evt.target.value.length == 0) this.setState({ pin1: evt.target.value });
  }
  updatePin2(evt) {
    if(evt.target.value.length == 1 || evt.target.value.length == 0) this.setState({ pin2: evt.target.value });
  }
  updatePin3(evt) {
    if(evt.target.value.length == 1 || evt.target.value.length == 0) this.setState({ pin3: evt.target.value });
  }
  updatePin4(evt) {
    if(evt.target.value.length == 1 || evt.target.value.length == 0) this.setState({ pin4: evt.target.value });
  }
  openCard = () => {
    // START: GET HOME PAGE TEXT
    var pin = ""+this.state.pin1+this.state.pin2+this.state.pin3+this.state.pin4; 
		const userData = {utente: this.state.utente, pin: pin}
    axios.post(global.config.apiUrl+"viewCard", userData)
    .then(res => {
      if(res.data!=0){
        this.setState({cardError: 0});
        sessionStorage.setItem('cardPin', pin);
        window.location.href = '/card/'+res.data;
      }
      else{
        this.setState({cardError: 1});
      }
		})
		// END: GET HOME PAGE TEXT
  }
  scrollEvent = (e) => {
    e.preventDefault();
    document.getElementById("navbar-top").scrollIntoView({
      behavior: 'smooth'
    });
  }
  componentDidMount(){
    ReactGA.pageview(window.location.pathname);
    document.getElementById("navbar-top").scrollIntoView({
      behavior: 'smooth'
    });
    // START: GET HOME PAGE TEXT
		const userData = {page_id: 1}
    axios.post(global.config.apiUrl+"getPage", userData)
    .then(res => {
      this.setState({ homeTitle: res.data.NAME });
      this.setState({ homeText: res.data.DESCRIPTION });
      this.setState({ homeImage1: res.data.IMAGE });
      this.setState({ homeImage2: res.data.IMAGE2 });
      this.setState({ homeImage3: res.data.IMAGE3 });
		})
		// END: GET HOME PAGE TEXT
  }
  componentDidUpdate(){
    if($(window).width() <= 600) $('.flip-card').css('zoom',(($(window).width()-30)/535));
  }

  render() {
    return (
      <div className="home-container">
          <div className="card-banner">
            <Container className="card-container">
              {this.state.cardError == 1 &&
                <div className="card-error">
                  <p>Combinação inválida de nº de utente e pin!</p>
                </div>
              }
              <div className="card-input-container">
                <div className="card-user-number">
                  <label>Número de utente</label>
                  <input type="number" pattern="\d*" placeholder="000 000 000" onChange={evt => this.updateUtente(evt)}/>
                </div>
                <div className="card-pin-number">
                  <label>Número de pin</label>
                  <input 
                    id="pin-number-square-1" 
                    maxLength="1" 
                    type="number" 
                    pattern="\d*" 
                    onKeyUp={this.inputFocus2.setFocus}
                    value={this.state.pin1}
                    onChange={evt => this.updatePin1(evt)}
                  />
                  <input 
                    id="pin-number-square-2" 
                    maxLength="1" 
                    type="number" 
                    pattern="\d*" 
                    ref={this.inputFocus2.ref}
                    onKeyUp={this.inputFocus3.setFocus} 
                    value={this.state.pin2}
                    onChange={evt => this.updatePin2(evt)}
                  />
                  <input 
                    id="pin-number-square-3" 
                    maxLength="1" 
                    type="number" 
                    pattern="\d*" 
                    ref={this.inputFocus3.ref}
                    onKeyUp={this.inputFocus4.setFocus} 
                    value={this.state.pin3}
                    onChange={evt => this.updatePin3(evt)}
                  />
                  <input 
                    id="pin-number-square-4" 
                    maxLength="1" 
                    type="number" 
                    pattern="\d*" 
                    ref={this.inputFocus4.ref} 
                    value={this.state.pin4}
                    onChange={evt => this.updatePin4(evt)}
                  />
                </div>
              </div>
              <div className="card-btn-container">
                <Button variant="secondary" className="showMobile" onClick={() => this.openCard()}>
                    Consultar cartão
                </Button>
                <div>
                  <InfoModal/>
                </div>
                <Button variant="secondary" className="hideMobile" onClick={() => this.openCard()}>
                    Consultar cartão
                </Button>
              </div>
            </Container>
          </div>
      </div>
    );
  }
}

function InfoModal() {
  const [show, setShow] = useState(false);
  const [recoveryUtente, setRecoveryUtente] = useState("");
  const [recoverySuccess, setRecoverySuccess] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setRecoverySuccess(0);
    setShow(true);
  }

  function pinRecovery(){
    const recoveryData = {utente: recoveryUtente}
    axios.post(global.config.apiUrl+"pinRecovery", recoveryData)
    .then(res => {
      if(res.data == 1) setRecoverySuccess(1);
      else setRecoverySuccess(2);
    })
  }

  return (
    <>
      <Button className="link-text-open-modal" onClick={handleShow}>
        Recuperar pin
      </Button>

      <Modal show={show} onHide={handleClose} backdrop='static' id="recover-pin-modal" centered keyboard="False">
        <Modal.Header closeButton>
          {recoverySuccess == 0 &&
            <Modal.Title>Recuperar pin</Modal.Title>
          }
          {recoverySuccess == 1 &&
            <Modal.Title>Sucesso</Modal.Title>
          }
          {recoverySuccess == 2 &&
            <Modal.Title>Erro</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          {recoverySuccess == 0 &&
            <div className="card-user-number">
              <label>Número de utente</label>
              <input type="number" placeholder="000 000 000" value={recoveryUtente} onChange={e => setRecoveryUtente(e.target.value)}/>
            </div>
          }
          {recoverySuccess == 1 &&
            <p>Foi enviado um email com o novo pin</p>
          }
          {recoverySuccess == 2 &&
            <p>Este nº de utente não existe</p>
          }
          
        </Modal.Body>
        
          {recoverySuccess == 0 &&
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={pinRecovery}>
                Enviar
              </Button>
            </Modal.Footer>
          }
          {recoverySuccess != 0 &&
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Fechar
              </Button>
            </Modal.Footer>
          }
      </Modal>
    </>
  );
}

export default Card;
